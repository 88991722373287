@import url('https://fonts.googleapis.com/css2?family=Lato&family=Six+Caps:wght@400&family=Source+Sans+Pro:wght@400&family=Poppins:wght@300&display=swap');

*{ 
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */
*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */
*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

.showcase{
  position: relative;
  right: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  color: #fff;
  z-index: 2;
}

.showcase header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 50px;
  position: flex;
  z-index: 10;
}
.logo{
  width: 100px;
}

.showcase video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.8;
  z-index: -1;
}

video::-webkit-media-controls {
  display: none;
}

video::-webkit-media-controls-start-playback-button {
  display: none;
}

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(to bottom, transparent 75%, black);
}

.text{
  text-align: center;
  position: relative;
  z-index: 3;
}

.text h1{
  font-family: six caps;
  font-weight: 400;
  font-size: 8em;
  text-transform: uppercase;
}

.text h3{
  font-family: source sans pro;
  font-weight: 400;
  font-size: 1.6em;
  text-transform: capitalize;
  margin-top: -1em;
}

.text a{
  display: inline-block;
  font-weight: 400;
  font-size: 1.2em;
  font-family: source sans pro;
  padding: 10px 30px;
  text-decoration: none;
  color: #fff;
  margin-top: 2em;
  border: 2px solid #fff;
  border-radius: 10px;
  margin-bottom: 35px;
  transition: 0.2s;
}

.text a:hover{
  color: #000;
  background: #fff;
}

.video-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -99;
  transition: opacity 400ms ease 0ms;
}
.tiny {
  filter: blur(10px);
  transform: scale(1, 0.96);
  transition: visibility 0ms ease 400ms;
}



/* ---------------------- contact section below */
.contact{
  font-family: source sans pro;
  position: relative;
  width: 100%;
  padding: 2em 0;
  background-color: #000;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.contact-div{
  width: 80%;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  display: flex;
}

.contact-div .left{
  max-width: 350px;
  padding-right: 50px;
}

.contact-div .right {
  padding-top: 7em;
  padding-left: 2em;
  width: 50%;
}

h1{
  font-family: six caps;
  font-weight: 400;
  font-size: 4em;
  text-transform: uppercase;
}

h3{
  font-weight: 400;
  padding-top: 1em;
}

form{
  width: 90%;
  max-width: 600px;
}

.input-group{
  margin-bottom: 30px;
  position: relative;
}

.input-group #message{
  height: 200px;
  white-space: pre;
}

input, textarea{
  width: 100%;
  padding: 10px;
  outline: 0;
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  font-size: 1em;
  border-radius: 5px;
}

label{
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px;
  color: #fff;
  cursor: text;
  transition: 0.2s;
}

button{
  padding: 10px 30px;
  color: #fff;
  outline: none;
  background: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.2s;
}

button:hover{
  color: #000;
  background: #fff;
}


input:focus~label,
input:valid~label,
textarea:focus~label,
textarea:valid~label{
  top: -35px;
  font-size: 14px;
}

/* ------------------------ footer section below */
.footer{
  position: relative;
  padding-top: 4em;
  background: #000;
}

.social{
  position: relative;
  background: #000;
  padding-bottom: 50px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social li{
  list-style: none;
}

.social li a{
  display: inline-block;
  margin-right: 10px;
  transform: scale(0.5);
  transition: 0.2s;
  filter: invert(1);
}

.social li a:hover{
  transform: scale(0.8);
}

/* ------------ media queries below */

@media screen and (max-width: 992px) {
  .contact-div {
    flex-direction: column;
    justify-content: space-between;
  }
  .contact-div .left{
    margin-top: 2em;
    padding: 0 1em;
    width: 90%;
    max-width: none;
  }
  .contact-div .right{
    margin-top: 6em;
    padding: 0 1em;
    width: 90%;
  }
  .text h1{
    margin-top: 0.3em;
    font-size: 4em;
  }
  .text h3{
    font-size: 1.2em;
  }

  .text a{
    margin-top: 1em;
  }
}
